header {
  flex: 1;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 122px;
  @media (max-width: 768px) {
    padding: 16px;
  }
  @media (max-width: 1024px) {
    padding: 15px 30px;
  }
}
button {
  display: inline-block;
  position: relative;
  outline: none;
  text-align: center;
  text-decoration: none;
  box-sizing: border-box;
  cursor: pointer;
  pointer-events: auto;
  border-radius: 4px;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  background: rgb(31, 45, 95);
  color: rgb(249, 185, 41);
  padding: 8px 24px;
  border: none;
}

.DesktopImg {
  width: 300px;
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
}

.MobileImg {
  width: 190px;
  height: 34px;
  display: block;
  @media (min-width: 768px) {
    display: none;
  }
}

.AccessAccountLink {
  font-family: 'Raleway', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  margin-right: 20px;
  color: #454e4f;
  @media (max-width: 768px) {
    display: none;
  }
}

.LoginContainer {
  display: flex;
  align-items: center;
}

.Button {
  max-height: 30px;
}
