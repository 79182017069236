@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@600&display=swap");
.logo-footer {
  padding-bottom: 25px;
  width: 240px;
  height: 69px;
}
.logo-footer2 {
  display: none;
}
.gap {
  margin-top: -1px;
  height: 25px;
  background-color: #e5e5e5;
}

footer {
  background-color: #002364;
  padding-bottom: 20px;
}
footer a,
span {
  text-decoration: none;
  margin: 30px;
}
footer section a,
span {
  color: #fff;
  text-decoration: none;
  font-size: 50px;
  font-weight: 100;
  font-family: "Courier New", Courier, monospace;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.headerFooter-img {
  display: flex;
  justify-self: center;
  align-self: center;
  width: 3%;
}
.headerFooter-h2 {
  display: flex;
  width: 94%;
  align-self: center;
  margin-left: 0.5rem;
}
.plus {
  display: flex;
  width: 3%;
  align-self: center;
  justify-self: flex-end;
  margin-right: 0px;
}
footer section {
  display: flex;
  justify-content: flex-start;
  border-bottom: 2px solid #e3e0e2;
  padding: 0 100px;

  font-style: normal;
  font-weight: bold;
  line-height: 160%;

  font-feature-settings: "pnum" on, "lnum" on;

  color: #ffffff;
  cursor: pointer;
}
.footer-bottom {
  margin-top: 25px;
  padding: 0 0 0 100px;
  width: 84%;
}
nav {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  text-decoration-line: underline;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #fff;
}
ul {
  display: flex;
  padding-left: 42px;
}
li {
  list-style: none;
  padding-right: 100px;
  margin-left: -40px;
}
li a {
  color: white;
  margin: 0;
}
footer p {
  color: #fff;
  margin-bottom: 6px;
}
hr {
  width: 100%;
  margin: 20px 0;
}
.footer-end {
  padding: 0 100px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}
.footer-table {
  width: 100%;
}
.footer-table td {
  max-width: 100%;
}
.copyright {
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 768px) {
  main {
    margin: 0;
    padding: 0;
    height: max-content;
    margin-bottom: 30px;
  }
  a {
    text-decoration: none;
    color: #002364;
  }
  .item-img2 {
    margin-left: 70px;
    flex-grow: 1;
    justify-self: center;
    display: block;
  }

  .logo-footer {
    padding-bottom: 0px;
    width: 180px;
    height: 39px;
  }

  .footer-table p {
    font-size: 11px;
  }

  .responsive {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .header-top {
    height: 72px;
    margin: 21px 0;
    margin-top: 0px;
    margin-left: 30px;
    margin-right: 30px;
    padding: 0;
  }
  .item-a {
    display: none;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    flex-grow: 1;
  }
  .item-img {
    display: none;
    margin-left: 32%;
    flex-grow: 1;
    justify-self: center;
  }
  .container-main {
    align-items: center;
    font-size: 13px;
  }
  .title {
    width: 89%;
  }
  .title p {
    width: 100%;
    font-size: 18px;
    font-weight: 600;
  }
  .description {
    width: 91%;
  }
  .description p {
    font-size: 18px;
    line-height: 30px;
    margin-left: 4px;
  }
  .description h3 {
    font-size: 18px;
  }
  .button-access {
    width: 87vw;
  }
  .gap {
    height: 21px;
  }
  /* ## Footer begin ## */
  footer {
    width: 100%;
    background-color: #002364;
  }
  .headerFooter-img {
    width: 4%;
  }
  .headerFooter-h2 {
    width: 80%;
    margin-left: 35px;
    font-size: 18px;
    font-weight: 600;
  }
  .plus {
    width: 20%;
    font-weight: 100;
  }
  footer section {
    padding: 0 20px;
    line-height: 234%;
  }
  .footer-bottom {
    padding: 0 20px;
  }
  nav {
    font-weight: 300;
  }
  ul {
    flex-direction: column;
    padding-inline-start: 0px;
  }
  li {
    font-size: 13px;
    padding-right: 0;
    margin-left: 0px;
    margin-top: 10px;
  }
  hr {
    margin-left: 0px;
  }
  .footer-end {
    padding: 0 20px;
    font-weight: 300;
    font-size: 11px;
  }
  .footer-table td {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 10px;
  }
}
