h1 {
  color: #002364;
  padding-bottom: 24px;
}
.partners {
  background-color: white;
  padding: 32px 24px;
}
.same-box {
  display: flex;
  gap: 16px;
}
.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 32px 0;
}

.logoPartner {
  max-height: 150px;
  margin: 0 auto;
  aspect-ratio: 16/9;
}

@media screen and (min-width: 1024px) {
  .same-box {
      gap: 32px;
  }
  
  .container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 32px;
  }

  .partners {
    padding: 32px;
  }
}

@media screen and (min-width: 768px) {
  h1 {
    padding-bottom: 18px;
  }
  .partners {
    padding: 32px 100px;
  }
}