.DesktopOnly {
  display: none;
  @media (min-width: 769px) {
    display: block;
  }
}

.MobileOnly {
  display: block;
  @media (min-width: 769px) {
    display: none;
  }
}
