.HomeOverlay {
  display: flex;
  position: absolute;
  flex-direction: column;
  right: 5%;
  top: 10px;
  color: rgb(31, 44, 95);
  
  @media (min-width: 768px) {
    max-width: 50%;
  }
  @media (min-width: 1025px) {
    max-width: 39%;
  }
}

.Title {
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  color: rgb(31, 44, 95);
  margin-bottom: 12px;
  @media (min-width: 768px) {
    font-size: 32px;
  }
  @media (min-width: 1025px) {
    font-size: 40px;
  }
  @media (min-width: 1700px) {
    font-size: 50px;
  }
}

.Text {
  font-size: 16px;
  color: #ffffff;
  @media (min-width: 1025px) {
    font-size: 24px;
  }
  @media (min-width: 1367px) {
    font-size: 28px;
  }
  @media (min-width: 1700px) {
    font-size: 32px;
  }
}

.BlueButton {
  cursor: pointer;
  font-weight: bold;
  max-width: 200px;
  font-size: 14px;
  line-height: 19px;
  color: #002364;
  text-align: center;
  background: #ffd302;
  box-shadow: 0px -2px 2px rgba(45, 41, 38, 0.05),
    0px 8px 12px rgba(45, 41, 38, 0.16);
  border-radius: 8px;
  padding: 16px 36px;
  margin-top: 24px;
  @media (min-width: 768px) {
    font-size: 16px;
    margin-bottom: 12px;
    max-width: 215px;
  }
  @media (min-width: 1025px) {
    font-size: 32px;
    margin-top: 32px;
    margin-bottom: 12px;
    max-width: 400px;
  }
}
